import PropTypes from 'prop-types'
import { useMemo } from 'react'
// @mui
import { CssBaseline, responsiveFontSizes } from '@mui/material'
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles'
//
import palette from './palette'
import shadows from './shadows'
import typography from './typography'
import GlobalStyles from './globalStyles'
import customShadows from './customShadows'

// ----------------------------------------------------------------------

const ThemeProvider = (props) => {
  const { children } = props

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [],
  )

  const theme = createTheme(themeOptions)
  theme.components = {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          border: '1.5px solid #0000001A',
          boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.06)',
          [theme.breakpoints.up('md')]: {
            padding: '25px',
          },
          // [theme.breakpoints.down("md")]: {
          //   padding: "25px 0px",
          // },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
          fontWeight: 500,
          fontSize: '14px',
          whiteSpace: 'wrap !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '15px 20px !important',
        },
        root: {
          borderRadius: '12px !important',
        },
        inputMultiline: {
          padding: '0px !important',
          borderRadius: '0px !important',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        input: {
          padding: '0px !important',
        },
        select: {
          paddingTop: '5px !important',
          paddingBottom: '5px !important',
          paddingLeft: '8px !important',
          paddingRight: '24px !important',
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              top: 'calc(50% - .5em)',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: '12px',
          fontSize: '16px',
          fontWeight: 400,
          borderWidth: '0.8px',
        },
        notchedOutline: {
          border: '1px solid #E6E6E6',
          backgroundColor: 'rgba(159, 158, 150, 0.08)',
          borderRadius: '12px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        standard: {
          borderRadius: '12px 0 0 12px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 15,
        },
        icon: {
          top: 'unset',
          marginRight: 4,
        },
        // icon: {
        //   top: '30%',
        //   marginRight: '6px',
        // },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'unset',
          },
          '&.Mui-active': {
            color: 'white',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          '.MuiIconButton-edgeEnd': {
            backgroundColor: '#276ad3',
            borderRadius: 0,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          marginTop: '8px !important',
          '&.Mui-completed': {
            fontSize: '12px',
            fontWeight: 500,
            color: '#276ad3',
          },
          '&.Mui-active': {
            fontSize: '12px',
            fontWeight: 500,
            color: '#276ad3',
          },
          '&.Mui-disabled': {
            fontSize: '12px',
            fontWeight: 500,
            color: '#011B36',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          top: '16px',
          '&.Mui-completed .MuiStepConnector-line': {
            borderColor: '#276ad3',
          },
        },
        line: {
          borderColor: '#011B36',
          borderTopWidth: '2px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          color: '#000000',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '12px',
          padding: '10px 18px',
          // maxWidth: '410px',
        },
        startIcon: {
          [theme.breakpoints.down('md')]: {
            margin: '0',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          height: '40px',
          fontSize: '14px',
          fontWeight: 500,
          color: 'white',
          backgroundColor: '#276ad3',
          border: '1px solid #276ad3',
          borderRadius: '8px',
        },
        outlined: {
          height: '40px',
          fontSize: '14px',
          fontWeight: 500,
          color: '#9C9C9C',
          backgroundColor: '#F5F5F5',
          border: '1px solid #9C9C9C1F',
          borderRadius: '8px',
        },
        label: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor:
                  theme.palette.mode === 'light'
                    ? '#276ad3'
                    : theme.palette.grey[600],
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#276ad3',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor:
              theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '4px !important',
        },
        popupIndicator: {
          marginRight: '2px',
        },
        clearIndicator: {
          visibility: 'visible',
          color: '#000000',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
          border: '0.5px solid #011B3614',
          boxShadow: '0px 0px 10px 3px #0000000D',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          // border: '1.5px solid #276ad399',
          boxShadow: '0px 0px 60.900001525878906px 0px #0000001A',
          borderRadius: '16px',
          // paddingBottom: '10px',
          padding: '30px 24px',
          [theme.breakpoints.down('sm')]: {
            padding: '20px 16px',
            paddingTop: '30px',
            margin: '15px',
            width: 'calc(100% - 30px)',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiOtpInput: {
      styleOverrides: {
        TextField: { backgroundColor: 'red' },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#276ad3',
          fontSize: '14px',
          fontWeight: 500,
          width: '46px',
          height: '46px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: '#276ad3',
          border: '1.5px solid #276ad399',
          boxShadow: '0px 0px 60.900001525878906px 0px #0000001A',
          marginTop: '0px !important',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: '20px',
          boxShadow: '0px 5px 16px 0px #011B360F',
          borderRadius: '20px',
          border: ' 1px solid rgba(0, 0, 0, 0.07)',
          color: '#000000',
          fontSize: 22,
          fontWeight: 500,
          padding: '20px 0',
          transition: 'all 0.3s',
          ':first-of-type': {
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          },
          ':last-of-type': {
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
          },
          '::before': {
            backgroundColor: 'transparent',
            height: 0,
          },
          '&.Mui-expanded': {
            border: '2px solid #276ad3',
            transition: 'all 0.3s',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color: '#000000',
          fontSize: 18,
          fontWeight: 400,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          columnGap: '10px',
        },
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        expandIconWrapper: {
          minWidth: '50px ',
          minHeight: '50px ',
          backgroundColor: theme.palette.common.white,
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #0000000A',
          boxShadow: '0px 5px 16px 0px #080F340F',
          transform: 'rotate(270deg)',
          transition: 'all 0.3s',
          [theme.breakpoints.down('md')]: {
            minWidth: '35px ',
            minHeight: '35px ',
          },
          svg: {
            path: {
              stroke: theme.palette.primary.main,
            },
          },
          '&.Mui-expanded': {
            backgroundColor: theme.palette.primary.main,
            transform: 'rotate(360deg)',
            transition: 'all 0.3s',
            svg: {
              path: {
                stroke: theme.palette.common.white,
              },
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          border: '1.5px solid #276ad3',
          color: '#276ad3',
          fontWeight: 500,
          '&.Mui-selected': {
            backgroundColor: '#276ad3',
            color: '#FFF',
            ':hover': {
              backgroundColor: '#276ad3',
              color: '#FFF',
            },
          },
          ':hover': {
            backgroundColor: '#276ad3',
            color: '#FFF',
          },
        },
        ellipsis: {
          color: '#276ad3',
        },
        previousNext: {
          border: '1.5px solid #276ad3',
          color: '#276ad3',
          ':hover': {
            backgroundColor: '#276ad3',
            color: '#FFF',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontSize: '14px',
          paddingRight: '42px',
        },
      },
    },
    // MuiPaginationItem: {
    //   styleOverrides: {
    //     previousNext: {
    //       border: 'none',
    //       marginLeft: '16px',
    //       marginRight: '16px',
    //     },
    //     root: {
    //       fontSize: '8.438px',
    //       fontWeight: 700,
    //       '&.Mui-selected': {
    //         backgroundColor: theme.palette.primary.main,
    //         border: '0.469px solid #8C7DFF',
    //         color: theme.palette.common.white,
    //       },
    //     },
    //     page: {
    //       color: '#BCBCBC',
    //       borderRadius: '2.813px',
    //       border: '0.469px solid #BCBCBC',
    //     },
    //   },
    // },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          zIndex: 1000,
        },
      },
    },
    MuiDigitalClock: {
      styleOverrides: {
        item: {
          justifyContent: 'center',
          [theme.breakpoints.down('sm')]: {
            minHeight: '40px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          marginTop: '4px',
          marginLeft: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 18,
          lineHeight: 1.1,
          [theme.breakpoints.down('md')]: {
            fontSize: 16,
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 14,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 'unset',
          fontSize: 14,
          fontWeight: 500,
          zIndex: 1,
          color: '#9C9C9C',
          maxWidth: 'unset',
          textTransform: 'none',
          paddingLeft: '3px',
          paddingRight: '3px',
          '&.Mui-selected': {
            color: 'white',
          },
          [theme.breakpoints.down('lg')]: {
            fontSize: 12,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '100%',
          borderRadius: '8px',
        },
        flexContainer: {},
        root: {
          minHeight: 'unset',
          borderRadius: '12px',
          border: '1px solid rgba(140, 123, 255, 0.14)',
          background: '#83899B2B',
          padding: '2px',
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        ripple: {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: { height: '6px' },
        thumb: {
          width: 16,
          height: 16,
        },
      },
    },
  }

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default ThemeProvider
