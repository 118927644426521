import axios from 'axios'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import firebaseConfig from './firebase.config.json'

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Cloud Messaging and get a reference to the service

export let messaging = null

try {
  if (await isSupported()) {
    messaging = getMessaging(app)
  }
} catch (error) {}

export const requestPermission = async () => {
  console.log('Requesting permission...')
  try {
    if ('Notification' in window && Notification.permission === 'granted') {
      generateToken()
    } else {
      const permission = await Notification.requestPermission()

      if (permission === 'granted') {
        console.log('Notification permission granted.')
        generateToken()
      }
    }
  } catch (error) {}
}

const generateToken = () => {
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        try {
          const config = {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }

          let deviceId = localStorage.getItem('deviceId')
          if (!deviceId) {
            deviceId = 'web' + Math.random().toString(20).substring(2, 14)
            localStorage.setItem('deviceId', deviceId)
          }
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/device-tokens`,
              {
                deviceId: deviceId,
                deviceType: 'Web',
                token: currentToken,
              },
              config,
            )
            .catch(() => {})
        } catch (error) {
          console.log(error)
        }
      } else {
        console.log(
          'No registration token available. Request permission to generate one.',
        )
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
    })
}
