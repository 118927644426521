import React, { lazy, useEffect } from 'react'
import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom'
import FourOhFour from '../pages/FourOhFour'
import { useAuthContext } from '../hooks/useAuthContext'
import { useAdminAuthContext } from '../hooks/useAdminAuthContext'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { useLogout } from '../hooks/useLogout'
import { retry } from '../common/commonFunctions'

const CustomerSidebar = lazy(() =>
  retry(() => import('../layout/CustomerSidebar')),
)
const AdminSidebar = lazy(() => retry(() => import('../layout/AdminSidebar')))
const Login = lazy(() => retry(() => import('../pages/Login')))
const AdminLogin = lazy(() => retry(() => import('../pages/admin/AdminLogin')))
const Verification = lazy(() => retry(() => import('../pages/Verification')))
const SetupProfile = lazy(() => retry(() => import('../pages/SetupProfile')))
const SelectProfession = lazy(() =>
  retry(() => import('../pages/SelectProfession')),
)
const SetupProfileStep2 = lazy(() =>
  retry(() => import('../pages/SetupProfileStep2')),
)
const Success = lazy(() => retry(() => import('../pages/Success')))
const TermsAndConditions = lazy(() =>
  retry(() => import('../pages/TermsAndConditions')),
)
const PrivacyPolicy = lazy(() => retry(() => import('../pages/PrivacyPolicy')))
const ChatRoomDesclamier = lazy(() =>
  retry(() => import('../pages/ChatRoomDesclamier')),
)
const UploadingContentDesclamier = lazy(() =>
  retry(() => import('../pages/UploadingContentDesclamier')),
)

const whitelistRoutes = [
  '/setup-profile',
  '/setup-profile-step-2',
  '/profession',
]

const Routes = () => {
  const { token } = useAuthContext()
  const { admin } = useAdminAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { logout } = useLogout()

  const getUserDetails = async () => {
    const token = localStorage.getItem('token')
    // const recentlyLoggedOut = sessionStorage.getItem('recentlyLoggedOut')

    const config = {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    // if (recentlyLoggedOut === 'true') {
    //   sessionStorage.removeItem('recentlyLoggedOut')
    //   return
    // }

    if (token) {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API_URL}/users`,
          config,
        )

        if (data?.data?.data) {
          if (!data?.data?.data?.profilePic) {
            navigate('/setup-profile', { state: 'setup' })
            if (!whitelistRoutes.includes(location.pathname)) {
              enqueueSnackbar('Kindly fill your profile details first', {
                variant: 'warning',
              })
            }
          } else if (!data?.data?.data?.country?.id) {
            navigate('/setup-profile-step-2', { state: 'setup' })
            if (!whitelistRoutes.includes(location.pathname)) {
              enqueueSnackbar('Kindly fill your profile details first', {
                variant: 'warning',
              })
            }
          } else if (data?.data?.data?.userProfessions?.length === 0) {
            navigate('/profession', { state: 'setup' })
            if (!whitelistRoutes.includes(location.pathname)) {
              enqueueSnackbar('Kindly fill your profile details first', {
                variant: 'warning',
              })
            }
          }
        }
      } catch (error) {
        console.log(error)
        if (error?.response?.data?.statusCode === 401) {
          logout()
          enqueueSnackbar(error?.response?.data?.message, {
            variant: 'error',
          })
        }
      }
    }
  }

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden && !location?.pathname?.includes('admin')) {
  //       getUserDetails()
  //     }
  //   }

  //   document.addEventListener('visibilitychange', handleVisibilityChange)

  //   if (!location?.pathname?.includes('admin')) getUserDetails()

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange)
  //   }
  // }, [])

  useEffect(() => {
    if (!location?.pathname?.includes('admin')) getUserDetails()
  }, [])

  useEffect(() => {
    if (location?.pathname === '/user/home') getUserDetails()
  }, [location?.pathname])

  const routes = useRoutes([
    {
      path: '/',
      element: !token ? <Login /> : <Navigate replace to="/user/home" />,
    },
    {
      path: '/verification',
      element: <Verification />,
    },
    {
      path: '/setup-profile',
      element: token ? <SetupProfile /> : <Navigate replace to="/" />,
    },
    {
      path: '/setup-profile-step-2',
      element: token ? <SetupProfileStep2 /> : <Navigate replace to="/" />,
    },
    {
      path: '/profession',
      element: token ? <SelectProfession /> : <Navigate replace to="/" />,
    },
    {
      path: '/success',
      element: token ? <Success /> : <Navigate replace to="/" />,
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/chat-room-disclaimer',
      element: <ChatRoomDesclamier />,
    },
    {
      path: '/uploading-content-disclaimer',
      element: <UploadingContentDesclamier />,
    },
    {
      path: '/user',
      element: <Navigate replace to="/user/home" />,
    },
    {
      path: '/user/*',
      element: token ? <CustomerSidebar /> : <Navigate replace to="/" />,
    },
    {
      path: '/admin/login',
      element: !admin ? <AdminLogin /> : <Navigate to="/admin/users" />,
    },
    {
      path: '/admin/*',
      element: admin ? (
        <AdminSidebar />
      ) : (
        <Navigate replace to="/admin/login" />
      ),
    },
    {
      path: '/*',
      element: <Navigate replace to="/404" />,
    },
    {
      path: '/404',
      element: <FourOhFour isAdmin={false} />,
    },
  ])
  return routes
}

export default Routes
