import { ReactComponent as PDFIcon } from '../resources/icons/Pdf_File_Icon.svg'
import { ReactComponent as TextIcon } from '../resources/icons/Text_File_Icon.svg'
import { ReactComponent as DocIcon } from '../resources/icons/Doc_File_Icon.svg'
import { ReactComponent as PngIcon } from '../resources/icons/Png_File_Icon.svg'
import { ReactComponent as PptIcon } from '../resources/icons/Ppt_File_Icon.svg'

export const genders = [
  'Male',
  'Female',
  'Non-binary',
  'Genderqueer',
  'Transgender Man',
  'Transgender Woman',
  'Other',
]

export const integerRegExp = /^\d+$/

export const floatRegExp = /^(\d*\.{0,1}\d{0,2}$)/

export const textRegExp = /(^[a-z .,'"(){}[\]]+$)/i

export const nameRegExp = /(^[a-z ]+$)/i

export const usernameRefExp = /^(?:[a-z._]*[0-9]){0,3}[a-z._]*$/

export const urlRegExp =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]|www\.[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]|[-A-Z0-9+&@#\/%=~_|]+\.[A-Z]{2,})/gi

export const tooltipContent = {
  username: [
    `Only alphabets and numbers are allowed in this field. No special characters except ' . ' and ' _ '.`,
    `Maximum 3 numbers are allowed.`,
    `Uppercase letter not allowed in this field.`,
  ],
  name: [`Only alphabets are allowed in this field.`],
  text: [
    `Only alphabets are allowed in this field.`,
    `Only " ,.'{}[]()" " special characters are allowed.`,
  ],
}

export const ImageFilesType = ['image/png', 'image/jpg', 'image/jpeg']

export const TextFilesType = [
  'text/plain',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/png',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]

export const VideoFilesType = ['video/mp4', 'video/ogg', 'video/webm']

export const AudioFilesType = ['audio/mpeg', 'audio/wav', 'audio/ogg']

export const textFileIcon = {
  txt: <TextIcon />,
  pdf: <PDFIcon />,
  doc: <DocIcon />,
  docx: <DocIcon />,
  png: <PngIcon />,
  ppt: <PptIcon />,
  pptx: <PptIcon />,
}

export const textFile = {
  txt: 'TEXT',
  pdf: 'PDF',
  doc: 'DOC',
  docx: 'DOC',
  png: 'PNG',
  ppt: 'PPT',
  pptx: 'PPT',
}

export const audioFile = {
  'audio/mpeg': 'MP3',
  'audio/wav': 'WAV',
  'audio/ogg': 'OGG',
  mp3: 'MP3',
}

export const notificationTypes = {
  userProfileNotification: [5, 6],
  userPostNotification: [1, 4, 10],
  userPitchNotification: [2, 3],
  userRequestToViewNotification: [7],
  userAcceptedViewRequestNotification: [8],
}
