import { createContext, useState } from 'react'

export const FilterContext = createContext('Light')

export const FilterContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [filterCount, setFilterCount] = useState(0)

  const openFilter = () => {
    setIsOpen((prev) => !prev)
  }
  const closeFilter = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <FilterContext.Provider
      value={{ isOpen, filterCount, openFilter, closeFilter, setFilterCount }}
    >
      {children}
    </FilterContext.Provider>
  )
}
