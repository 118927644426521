import { useSnackbar } from 'notistack'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
  const { dispatch } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()

  const logout = () => {
    // remove token from storage
    localStorage.clear()
    // sessionStorage.setItem('recentlyLoggedOut', 'true')

    enqueueSnackbar('You are successfully logged out', {
      variant: 'success',
    })

    // dispatch logout action
    dispatch({ type: 'LOGOUT' })
    try {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister()
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  return { logout }
}
