import { createContext, useState } from 'react'

export const ChatCountContext = createContext()

export const ChatCountContextProvider = ({ children }) => {
  const [unreadChatCount, setUnreadChatCount] = useState(0)

  return (
    <ChatCountContext.Provider value={{ unreadChatCount, setUnreadChatCount }}>
      {children}
    </ChatCountContext.Provider>
  )
}
