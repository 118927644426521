import AWS from 'aws-sdk'
import axios from 'axios'
import imageCompression from 'browser-image-compression'
import { AES, enc, mode, pad } from 'crypto-js'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'
import { urlRegExp } from './constants'

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const isNumber = (number) => {
  const isNum = /^\d+$/.test(number)

  return isNum
}

export const shortName = (name) => {
  return name
    ? name
        ?.match(/\b(\w)/g)
        ?.slice(0, 2)
        ?.join('')
        ?.toUpperCase()
    : ''
}

export const formatNumber = (number) => {
  if (number < 0) return 0

  if (number >= 10000000)
    number =
      (number / 10000000).toLocaleString('en-IN', {
        maximumFractionDigits: 2,
      }) + ' Cr'
  else if (number >= 100000)
    number =
      (number / 100000).toLocaleString('en-IN', { maximumFractionDigits: 2 }) +
      ' Lac'
  else if (number >= 1000)
    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return number

  // return number > 0
  //   ? number.toLocaleString('en-IN', { maximumFractionDigits: 2 })
  //   : 0
}

export const formatNumberWithPrecision = (number) => {
  return number > 0 ? Math.round((number + Number.EPSILON) * 100) / 100 : 0
}

export const formatNumberWithNoPrecision = (number) => {
  return number > 0 ? number.toFixed(0) : 0
}

export const getPercentage = (number1, number2) => {
  const offPercentage = number2
    ? number2 !== '0'
      ? formatNumberWithPrecision(((number1 - number2) / number1) * 100)
      : 0
    : 0

  return offPercentage + '% Off'
}

export const isValidPostalCode = (postalCode, countryCode) => {
  let postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/

  switch (countryCode) {
    case 'GB':
      postalCodeRegex =
        /^GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}$/
      break
    case 'JE':
      postalCodeRegex = /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/
      break
    case 'GG':
      postalCodeRegex = /^GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/
      break
    case 'IM':
      postalCodeRegex = /^IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/
      break
    case 'US':
      postalCodeRegex = /^\d{5}([ \-]\d{4})?$/
      break
    case 'CA':
      postalCodeRegex =
        /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/
      break
    case 'DE':
      postalCodeRegex = /^\d{5}$/
      break
    case 'JP':
      postalCodeRegex = /^\d{3}-\d{4}$/
      break
    case 'FR':
      postalCodeRegex = /^\d{2}[ ]?\d{3}$/
      break
    case 'AU':
      postalCodeRegex = /^\d{4}$/
      break
    case 'IT':
      postalCodeRegex = /^\d{5}$/
      break
    case 'CH':
      postalCodeRegex = /^\d{4}$/
      break
    case 'AT':
      postalCodeRegex = /^\d{4}$/
      break
    case 'ES':
      postalCodeRegex = /^\d{5}$/
      break
    case 'NL':
      postalCodeRegex = /^\d{4}[ ]?[A-Z]{2}$/
      break
    case 'BE':
      postalCodeRegex = /^\d{4}$/
      break
    case 'DK':
      postalCodeRegex = /^\d{4}$/
      break
    case 'SE':
      postalCodeRegex = /^\d{3}[ ]?\d{2}$/
      break
    case 'NO':
      postalCodeRegex = /^\d{4}$/
      break
    case 'BR':
      postalCodeRegex = /^\d{5}[\-]?\d{3}$/
      break
    case 'PT':
      postalCodeRegex = /^\d{4}([\-]\d{3})?$/
      break
    case 'FI':
      postalCodeRegex = /^\d{5}$/
      break
    case 'AX':
      postalCodeRegex = /^22\d{3}$/
      break
    case 'KR':
      postalCodeRegex = /^\d{3}[\-]\d{3}$/
      break
    case 'CN':
      postalCodeRegex = /^\d{6}$/
      break
    case 'TW':
      postalCodeRegex = /^\d{3}(\d{2})?$/
      break
    case 'SG':
      postalCodeRegex = /^\d{6}$/
      break
    case 'DZ':
      postalCodeRegex = /^\d{5}$/
      break
    case 'AD':
      postalCodeRegex = /^AD\d{3}$/
      break
    case 'AR':
      postalCodeRegex = /^([A-HJ-NP-Z])?\d{4}([A-Z]{3})?$/
      break
    case 'AM':
      postalCodeRegex = /^(37)?\d{4}$/
      break
    case 'AZ':
      postalCodeRegex = /^\d{4}$/
      break
    case 'BH':
      postalCodeRegex = /^((1[0-2]|[2-9])\d{2})?$/
      break
    case 'BD':
      postalCodeRegex = /^\d{4}$/
      break
    case 'BB':
      postalCodeRegex = /^(BB\d{5})?$/
      break
    case 'BY':
      postalCodeRegex = /^\d{6}$/
      break
    case 'BM':
      postalCodeRegex = /^[A-Z]{2}[ ]?[A-Z0-9]{2}$/
      break
    case 'BA':
      postalCodeRegex = /^\d{5}$/
      break
    case 'IO':
      postalCodeRegex = /^BBND 1ZZ$/
      break
    case 'BN':
      postalCodeRegex = /^[A-Z]{2}[ ]?\d{4}$/
      break
    case 'BG':
      postalCodeRegex = /^\d{4}$/
      break
    case 'KH':
      postalCodeRegex = /^\d{5}$/
      break
    case 'CV':
      postalCodeRegex = /^\d{4}$/
      break
    case 'CL':
      postalCodeRegex = /^\d{7}$/
      break
    case 'CR':
      postalCodeRegex = /^\d{4,5}|\d{3}-\d{4}$/
      break
    case 'HR':
      postalCodeRegex = /^\d{5}$/
      break
    case 'CY':
      postalCodeRegex = /^\d{4}$/
      break
    case 'CZ':
      postalCodeRegex = /^\d{3}[ ]?\d{2}$/
      break
    case 'DO':
      postalCodeRegex = /^\d{5}$/
      break
    case 'EC':
      postalCodeRegex = /^([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?$/
      break
    case 'EG':
      postalCodeRegex = /^\d{5}$/
      break
    case 'EE':
      postalCodeRegex = /^\d{5}$/
      break
    case 'FO':
      postalCodeRegex = /^\d{3}$/
      break
    case 'GE':
      postalCodeRegex = /^\d{4}$/
      break
    case 'GR':
      postalCodeRegex = /^\d{3}[ ]?\d{2}$/
      break
    case 'GL':
      postalCodeRegex = /^39\d{2}$/
      break
    case 'GT':
      postalCodeRegex = /^\d{5}$/
      break
    case 'HT':
      postalCodeRegex = /^\d{4}$/
      break
    case 'HN':
      postalCodeRegex = /^(?:\d{5})?$/
      break
    case 'HU':
      postalCodeRegex = /^\d{4}$/
      break
    case 'IS':
      postalCodeRegex = /^\d{3}$/
      break
    case 'IN':
      postalCodeRegex = /^\d{6}$/
      break
    case 'ID':
      postalCodeRegex = /^\d{5}$/
      break
    case 'IL':
      postalCodeRegex = /^\d{5}$/
      break
    case 'JO':
      postalCodeRegex = /^\d{5}$/
      break
    case 'KZ':
      postalCodeRegex = /^\d{6}$/
      break
    case 'KE':
      postalCodeRegex = /^\d{5}$/
      break
    case 'KW':
      postalCodeRegex = /^\d{5}$/
      break
    case 'LA':
      postalCodeRegex = /^\d{5}$/
      break
    case 'LV':
      postalCodeRegex = /^\d{4}$/
      break
    case 'LB':
      postalCodeRegex = /^(\d{4}([ ]?\d{4})?)?$/
      break
    case 'LI':
      postalCodeRegex = /^(948[5-9])|(949[0-7])$/
      break
    case 'LT':
      postalCodeRegex = /^\d{5}$/
      break
    case 'LU':
      postalCodeRegex = /^\d{4}$/
      break
    case 'MK':
      postalCodeRegex = /^\d{4}$/
      break
    case 'MY':
      postalCodeRegex = /^\d{5}$/
      break
    case 'MV':
      postalCodeRegex = /^\d{5}$/
      break
    case 'MT':
      postalCodeRegex = /^[A-Z]{3}[ ]?\d{2,4}$/
      break
    case 'MU':
      postalCodeRegex = /^(\d{3}[A-Z]{2}\d{3})?$/
      break
    case 'MX':
      postalCodeRegex = /^\d{5}$/
      break
    case 'MD':
      postalCodeRegex = /^\d{4}$/
      break
    case 'MC':
      postalCodeRegex = /^980\d{2}$/
      break
    case 'MA':
      postalCodeRegex = /^\d{5}$/
      break
    case 'NP':
      postalCodeRegex = /^\d{5}$/
      break
    case 'NZ':
      postalCodeRegex = /^\d{4}$/
      break
    case 'NI':
      postalCodeRegex = /^((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?$/
      break
    case 'NG':
      postalCodeRegex = /^(\d{6})?$/
      break
    case 'OM':
      postalCodeRegex = /^(PC )?\d{3}$/
      break
    case 'PK':
      postalCodeRegex = /^\d{5}$/
      break
    case 'PY':
      postalCodeRegex = /^\d{4}$/
      break
    case 'PH':
      postalCodeRegex = /^\d{4}$/
      break
    case 'PL':
      postalCodeRegex = /^\d{2}-\d{3}$/
      break
    case 'PR':
      postalCodeRegex = /^00[679]\d{2}([ \-]\d{4})?$/
      break
    case 'RO':
      postalCodeRegex = /^\d{6}$/
      break
    case 'RU':
      postalCodeRegex = /^\d{6}$/
      break
    case 'SM':
      postalCodeRegex = /^4789\d$/
      break
    case 'SA':
      postalCodeRegex = /^\d{5}$/
      break
    case 'SN':
      postalCodeRegex = /^\d{5}$/
      break
    case 'SK':
      postalCodeRegex = /^\d{3}[ ]?\d{2}$/
      break
    case 'SI':
      postalCodeRegex = /^\d{4}$/
      break
    case 'ZA':
      postalCodeRegex = /^\d{4}$/
      break
    case 'LK':
      postalCodeRegex = /^\d{5}$/
      break
    case 'TJ':
      postalCodeRegex = /^\d{6}$/
      break
    case 'TH':
      postalCodeRegex = /^\d{5}$/
      break
    case 'TN':
      postalCodeRegex = /^\d{4}$/
      break
    case 'TR':
      postalCodeRegex = /^\d{5}$/
      break
    case 'TM':
      postalCodeRegex = /^\d{6}$/
      break
    case 'UA':
      postalCodeRegex = /^\d{5}$/
      break
    case 'UY':
      postalCodeRegex = /^\d{5}$/
      break
    case 'UZ':
      postalCodeRegex = /^\d{6}$/
      break
    case 'VA':
      postalCodeRegex = /^00120$/
      break
    case 'VE':
      postalCodeRegex = /^\d{4}$/
      break
    case 'ZM':
      postalCodeRegex = /^\d{5}$/
      break
    case 'AS':
      postalCodeRegex = /^96799$/
      break
    case 'CC':
      postalCodeRegex = /^6799$/
      break
    case 'CK':
      postalCodeRegex = /^\d{4}$/
      break
    case 'RS':
      postalCodeRegex = /^\d{6}$/
      break
    case 'ME':
      postalCodeRegex = /^8\d{4}$/
      break
    case 'CS':
      postalCodeRegex = /^\d{5}$/
      break
    case 'YU':
      postalCodeRegex = /^\d{5}$/
      break
    case 'CX':
      postalCodeRegex = /^6798$/
      break
    case 'ET':
      postalCodeRegex = /^\d{4}$/
      break
    case 'FK':
      postalCodeRegex = /^FIQQ 1ZZ$/
      break
    case 'NF':
      postalCodeRegex = /^2899$/
      break
    case 'FM':
      postalCodeRegex = /^(9694[1-4])([ \-]\d{4})?$/
      break
    case 'GF':
      postalCodeRegex = /^9[78]3\d{2}$/
      break
    case 'GN':
      postalCodeRegex = /^\d{3}$/
      break
    case 'GP':
      postalCodeRegex = /^9[78][01]\d{2}$/
      break
    case 'GS':
      postalCodeRegex = /^SIQQ 1ZZ$/
      break
    case 'GU':
      postalCodeRegex = /^969[123]\d([ \-]\d{4})?$/
      break
    case 'GW':
      postalCodeRegex = /^\d{4}$/
      break
    case 'HM':
      postalCodeRegex = /^\d{4}$/
      break
    case 'IQ':
      postalCodeRegex = /^\d{5}$/
      break
    case 'KG':
      postalCodeRegex = /^\d{6}$/
      break
    case 'LR':
      postalCodeRegex = /^\d{4}$/
      break
    case 'LS':
      postalCodeRegex = /^\d{3}$/
      break
    case 'MG':
      postalCodeRegex = /^\d{3}$/
      break
    case 'MH':
      postalCodeRegex = /^969[67]\d([ \-]\d{4})?$/
      break
    case 'MN':
      postalCodeRegex = /^\d{6}$/
      break
    case 'MP':
      postalCodeRegex = /^9695[012]([ \-]\d{4})?$/
      break
    case 'MQ':
      postalCodeRegex = /^9[78]2\d{2}$/
      break
    case 'NC':
      postalCodeRegex = /^988\d{2}$/
      break
    case 'NE':
      postalCodeRegex = /^\d{4}$/
      break
    case 'VI':
      postalCodeRegex = /^008(([0-4]\d)|(5[01]))([ \-]\d{4})?$/
      break
    case 'PF':
      postalCodeRegex = /^987\d{2}$/
      break
    case 'PG':
      postalCodeRegex = /^\d{3}$/
      break
    case 'PM':
      postalCodeRegex = /^9[78]5\d{2}$/
      break
    case 'PN':
      postalCodeRegex = /^PCRN 1ZZ$/
      break
    case 'PW':
      postalCodeRegex = /^96940$/
      break
    case 'RE':
      postalCodeRegex = /^9[78]4\d{2}$/
      break
    case 'SH':
      postalCodeRegex = /^(ASCN|STHL) 1ZZ$/
      break
    case 'SJ':
      postalCodeRegex = /^\d{4}$/
      break
    case 'SO':
      postalCodeRegex = /^\d{5}$/
      break
    case 'SZ':
      postalCodeRegex = /^[HLMS]\d{3}$/
      break
    case 'TC':
      postalCodeRegex = /^TKCA 1ZZ$/
      break
    case 'WF':
      postalCodeRegex = /^986\d{2}$/
      break
    case 'XK':
      postalCodeRegex = /^\d{5}$/
      break
    case 'YT':
      postalCodeRegex = /^976\d{2}$/
      break
    default:
      postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/
  }
  return postalCodeRegex.test(postalCode)
}

let instance

export const S3Upload = (
  file,
  token,
  identityId,
  userUniqueId,
  folderName,
  shortName,
) => {
  return new Promise((resolve, reject) => {
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        IdentityId: identityId,
        Logins: {
          'cognito-identity.amazonaws.com': token,
        },
      }),
    })

    let extension = ''
    if (shortName === 'IMG') {
      extension = 'jpg'
    } else if (shortName === 'VID') {
      extension = 'mp4'
    } else if (shortName === 'AUD') {
      extension = 'mp3'
    } else {
      extension = file?.name?.split('.')[1]
    }

    const S3Client = new AWS.S3()

    const date = new Date()
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      // Using the date.getTime() method for a unique name;
      // however, you may want to consider alternative methods to avoid reliance on this approach.
      Key: `${process.env.REACT_APP_S3_MODE}/${userUniqueId}/${
        folderName === 'profilePic' ? folderName : 'pitch/' + folderName
      }/${shortName}_${date.getTime()}.${extension}`,
      Body: file,
      ContentType: file.type,
    }

    instance = S3Client.upload(params, function (err, data) {
      if (err) {
        instance = undefined
        console.error('Error uploading file:', err)
        reject(err)
      }
      saveFilePath(data?.Location)
      instance = undefined
      resolve({ ...data, name: file.name })
    })
  })
}

export const CompressImage = async (file) => {
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
    fileType: 'image/jpeg',
  }
  try {
    const compressedFile = await imageCompression(file, options)
    return compressedFile
  } catch (error) {
    console.log(error)
    enqueueSnackbar('Something went wrong', { variant: 'error' })
    return
  }
}

const saveFilePath = (path) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
    if (path) {
      axios.post(
        `${process.env.REACT_APP_API_URL}/pitch/file-url/store`,
        { path },
        config,
      )
    }
  } catch (error) {
    console.log(error)
  }
}

export const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const media = new Audio(reader.result)
      media.onloadedmetadata = () => resolve(media.duration)
    }
    reader.readAsDataURL(file)
    reader.onerror = (error) => reject(error)
  })

export const S3Delete = (path, token, identityId) => {
  return new Promise((resolve, reject) => {
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        IdentityId: identityId,
        Logins: {
          'cognito-identity.amazonaws.com': token,
        },
      }),
    })

    const S3Client = new AWS.S3()

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: `${
        process.env.REACT_APP_S3_MODE +
        path?.split(process.env.REACT_APP_S3_MODE)[1]
      }`,
    }

    S3Client.deleteObject(params, function (err, data) {
      if (err) {
        console.error('Error deleting file:', err)
        reject(err)
      }
      resolve()
    })
  })
}

export const S3Abort = () => {
  if (instance) {
    try {
      instance.abort()
    } catch (error) {
      console.log(error)
    }
  }
}

export const encryptMessage = (inputText) => {
  try {
    // Convert key and IV to WordArray
    const key = enc.Utf8.parse(process.env.REACT_APP_AES_ENC_KEY)
    const iv = enc.Utf8.parse(process.env.REACT_APP_AES_ENC_IV)

    // Encrypt the input text
    const encrypted = AES.encrypt(inputText, key, {
      iv: iv,
      mode: mode.CBC,
      padding: pad.Pkcs7,
    })

    return encrypted.toString()
  } catch (e) {
    console.error(e)
    return ''
  }
}

export const decryptMessage = (inputText) => {
  try {
    if (inputText) {
      // Convert key and IV to WordArray
      const key = enc.Utf8.parse(process.env.REACT_APP_AES_ENC_KEY)
      const iv = enc.Utf8.parse(process.env.REACT_APP_AES_ENC_IV)

      // Decrypt the input text
      const encrypted = AES.decrypt(inputText, key, {
        iv: iv,
        mode: mode.CBC,
        padding: pad.Pkcs7,
      })

      return encrypted.toString(enc.Utf8)
    } else {
      return ''
    }
  } catch (e) {
    console.error(e)
    return ''
  }
}

export const convertToHttpUrl = (url) => {
  // Check if the URL starts with 'www.'
  if (url.startsWith('http')) {
    // Prepend 'http://' to the URL
    return url
  } else {
    return `http://${url}`
  }
}

export const getDateDiff = (startDate, endDate) => {
  return moment
    .unix(startDate)
    .startOf('day')
    .diff(moment.unix(endDate).startOf('day'), 'days')
}

const isYesterday = (date) => {
  if (!date) return false // If the date is null or undefined, return false

  const yesterday = moment().subtract(1, 'days').startOf('day') // Get yesterday's date and set to start of the day
  const inputDate = moment(date).startOf('day') // Convert the input date to Moment.js object and set to start of the day

  return yesterday.isSame(inputDate) // Compare if both dates are the same
}

export const getTimeAgo = (date) => {
  let seconds = moment().diff(moment.unix(date), 'seconds')
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)
  let days = Math.floor(hours / 24)
  let phrase = ''

  if (seconds <= 0) {
    phrase = 'few moments ago'
  } else if (seconds < 45) {
    phrase = `${seconds} sec ago`
  } else if (seconds < 90) {
    phrase = '1 min ago'
  } else if (minutes < 45) {
    phrase = `${minutes} min ago`
  } else if (minutes < 90) {
    phrase = '1h ago'
  } else if (hours < 24) {
    phrase = `${hours}h ago`
  } else if (hours < 42) {
    if (isYesterday) {
      phrase = 'Yesterday'
    } else {
      phrase = `${moment.unix(date).format('MM/DD/YY')}`
    }
  } else if (days < 30) {
    phrase = `${days}d ago`
  } else if (days < 45) {
    phrase = '1 m ago'
  } else if (days < 365) {
    phrase = `${days / 30} m ago`
  } else {
    phrase = `${moment.unix(date).format('MM/DD/YY')}`
  }

  return phrase
}
